import React from 'react'
import { useTranslation } from "react-i18next";

const PaymentOpt = () => {
  const loadImage = (img) => require(`../assets/${img}`);
  const { t } = useTranslation();


  return (
    <div className='mt-20 md:px-20 px-4 py-20'>
      <div className='mb-20 text-justify'> 
        <h1 className='text-3xl mb-3 font-bold text-CostumPurple'> {t("title_paymentopt")}  
        </h1>
        <p className='text-gray-600'> {t("intro_paymentopt")} 
        </p>
      </div>
      <div className='mb-16  '>

        <div className='flex justify-center items-center flex-col md:flex-row text-center md:text-left gap-14  '>
         <div>
         <h2 className='font-semibold text-3xl mb-14  text-CostumPurple'>
 {t("paymentopt_title1")}
</h2>
        <p className='text-[1.1rem] text-gray-900 text-justify'> {t("paymentopt_title1_desc") } </p>
        
        </div><img src={loadImage('Wallet-amico.png')}
          className='md:h-[35%] md:w-[35%]' 
          alt="" />
      
      </div>
      </div>
      <div>
        <div className='flex justify-center items-center flex-col md:flex-row text-center md:text-left gap-14  '>
       <div>
       <h2 className='font-semibold text-3xl   text-CostumPurple mb-14 '> {t("paymentopt_title2")} </h2>

        <p  className='text-[1.1rem] text-gray-900 text-justify'> {t("paymentopt_title2_desc")} </p>
        </div>
        <img src={loadImage('Paid idea-pana.png')}
        className='md:h-[35%] md:w-[35%]' 
          alt="" />
        </div>
      </div>
    </div>
  )
}

export default PaymentOpt
