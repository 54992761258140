import React, { createContext, useContext, useState, useEffect } from 'react';

// Create the context
const BannerDataContext = createContext();

// Custom hook to use the BannerDataContext
export const useBannerData = () => useContext(BannerDataContext);

// Provider component
export const BannerDataProvider = ({ children }) => {
    const [banners, setBanners] = useState([]);

    // List of banner endpoints
    const bannerEndpoints = [
        'https://s3.us-east-1.amazonaws.com/o-risha.com/Machines+Images/Banners/picoway_banner.jpg',
        'https://s3.us-east-1.amazonaws.com/o-risha.com/Machines+Images/Banners/derma_banner.jpg',
        'https://s3.us-east-1.amazonaws.com/o-risha.com/Machines+Images/Banners/dietyshape_banner.jpg',
        'https://s3.us-east-1.amazonaws.com/o-risha.com/Machines+Images/Banners/st_laser_banner.jpg',
        'https://s3.us-east-1.amazonaws.com/o-risha.com/Machines+Images/Banners/orisha_lhr_banner.jpg',
        'https://s3.us-east-1.amazonaws.com/o-risha.com/Machines+Images/Banners/uv_st_banner.jpg',
        'https://s3.us-east-1.amazonaws.com/o-risha.com/Machines+Images/Banners/pfm_chair_banner.jpg',
        'https://s3.us-east-1.amazonaws.com/o-risha.com/Machines+Images/Banners/main_page_banner.jpg',
    ];

    useEffect(() => {
        Promise.all(
            bannerEndpoints.map((url) =>
                fetch(url)
                    .then((res) => {
                        if (!res.ok) {
                            throw new Error('Failed to fetch banner!');
                        }
                        return res.url; // We only need the URLs here
                    })
                    .catch((error) => {
                        console.error('Fetch error:', error);
                        return null; // Return null for failed requests to filter them out
                    })
            )
        ).then((results) => {
            const validBanners = results.filter(Boolean); // Filter out null values
            setBanners(validBanners);
        });
    }, []);

    return (
        <BannerDataContext.Provider value={banners}>
            {children}
        </BannerDataContext.Provider>
    );
};
