import React, { createContext, useContext, useState, useEffect } from 'react';

const JsonDataContext = createContext();

export const useJsonData = () => useContext(JsonDataContext);

export const JsonDataProvider = ({children}) => {
    const [data, setData] = useState([]);

    const endPoints = [
        'https://s3.us-east-1.amazonaws.com/o-risha.com/Data/orisha_carbon_p400.json',
        'https://s3.us-east-1.amazonaws.com/o-risha.com/Data/orisha_derma.json',
        'https://s3.us-east-1.amazonaws.com/o-risha.com/Data/orisha_dietyshape_2.json',
        'https://s3.us-east-1.amazonaws.com/o-risha.com/Data/orisha_laser_st.json',
        'https://s3.us-east-1.amazonaws.com/o-risha.com/Data/orisha_lhr_o1.json',
        'https://s3.us-east-1.amazonaws.com/o-risha.com/Data/orisha_uv_st.json',
        'https://s3.us-east-1.amazonaws.com/o-risha.com/Data/osrisha_pfm_chair.json',
      ];

       useEffect(() => {
         Promise.all(
           endPoints.map((endPoint) => 
           fetch(endPoint)
           .then((res) => {
             if(!res.ok){
               throw new Error("Fetch API call failed !");
             }
             return res.json()
           })
           .catch((error) => console.error('Fetch API error:',error))
           )
         ).then((results) => {
            const data = results.filter(Boolean);
            setData(data);
         });
         },[]);
        
      return(
        <JsonDataContext.Provider value={data}>
            { children }
        </JsonDataContext.Provider>
      )
}