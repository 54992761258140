import React from 'react'
import { Link } from 'react-router-dom'
import Button from '../../../components/ui component/Button'
import { useBlogData } from '../../../data/blogData'
import { useTranslation } from "react-i18next";


const BlogCard = ({ blog, title, date, description, image }) => {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <div className="max-w-sm rounded overflow-hidden shadow-lg bg-white m-4 h-[95%]">
        <img
          className="w-full object-cover h-80"
          src="https://s3.us-east-1.amazonaws.com/o-risha.com/Blog+Data/orisha_revolution.jpg"
          alt={title}
        />
        <div className="p-4">
          <h3 className="text-xl font-semibold text-gray-800 mb-3">{title}</h3>
          <div className="text-sm text-gray-500 mb-4">
            <span>
              <span className='font-bold  text-gray-800'>Published: </span>{date}
            </span>
          </div>
          <p className="text-gray-600 mb-4 truncated-text">{description[0]}</p>
          <Link to="/BlogDetail" state={{ blog }}>
            <Button title={t("learn_more_button_text")} />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default BlogCard
