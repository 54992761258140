import React, { createContext, useContext, useState, useEffect } from 'react';

const BlogDataContext = createContext();

export const useBlogData = () => useContext(BlogDataContext);

export const BlogDataProvider = ({ children }) => {
  const [blogData, setBlogData] = useState([]);

  const endpoint = ['https://s3.us-east-1.amazonaws.com/o-risha.com/Blog+Data/blog_article1_orisha_intro.json']

  useEffect(() => {


Promise.all(
    endpoint.map((endpoint) => 
    fetch(endpoint)
    .then((res) => {
      if(!res.ok){
        throw new Error("Fetch API call failed !");
      }
      return res.json()
    })
    .catch((error) => console.error('Fetch API error:',error))
    )
  ).then((results) => {
     const blogData = results.filter(Boolean);
     setBlogData(blogData);
  });
  },[]);
 


  return (
    <BlogDataContext.Provider value={blogData}>
      {children}
    </BlogDataContext.Provider>
  );
};
