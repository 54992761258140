import React from "react";
import { useJsonData } from "../../../data/JsonDataContext";
import { useTranslation } from "react-i18next";

const ProductTreatment = ({ componentTitle }) => {

  const data = useJsonData();
  const productToDisplay = data.find((product) => product.title === componentTitle);
  const { t, i18n } = useTranslation();

  return (
    <div className="bg-white p-10  justify-center items-center text-center min-h-screen">
      <h1 className="md:text-4xl text-2xl  font-bold text-CostumPurple mb-40 mt-16">
        {t("product_treatments_title")}
      </h1>
      <div className="w-full grid grid-cols-1 lg:grid-cols-3 md:gap-32 md:grid-cols-2">
        {productToDisplay[i18n.language].treatments.map((treatment,index) => (
          <div key={index} >
          <h2 className=" font-bold mt-12 text-CostumPurple text-xl">
            {treatment}
          </h2>
        </div>
        ))}
      </div>
    </div>
  );
};

export default ProductTreatment;
