import React from 'react'
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
    const location = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0); // Scroll to top on route change
    }, [location]); // Depend on location so it runs on route change
  
    return null;
}

export default ScrollToTop
