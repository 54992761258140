import React, { useState, useEffect, useRef } from "react";
import Button from "./ui component/Button";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useJsonData } from "../data/JsonDataContext";
const Navbar = () => {
  const loadImage = (img) => {
    return require(`../assets/${img}`);
  };
  const data= useJsonData();
  const [siteLang, setSiteLang] = useState("en");
  const location = useLocation();

 // Prevent dropdown from closing when clicking on nested dropdowns
const handleDropdownClick = (e) => {
  e.stopPropagation();
};

  const treatmentType = [
    ...new Set(data.flatMap((product) => product.application_area)),
  ];

  // Mapping treatment types to translation keys
  const treatmentTypeTranslationKeys = {
    skin: "treatmentType_Opt1",
    hair: "treatmentType_Opt2",
    body: "treatmentType_Opt3",
    "pelvic floor": "treatmentType_Opt4",
    others: "treatmentType_Opt5",
  };

  let [toggle, toggleSet] = useState(true);
  const productName = [...new Set(data.map((product) => product.title))];
  const [treatmentHover, setTreatmentHover] = useState(false);
  const [productHover, setProductHover] = useState(false);
  const [productOpen, productOpenSet] = useState(false);
  const [compagnyOpen, compagnyOpenSet] = useState(false);
  const [treatmentOpen, treatmentOpenSet] = useState(false);

  const productRef = useRef(null);
  const companyRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        productRef.current && !productRef.current.contains(event.target) &&
        companyRef.current && !companyRef.current.contains(event.target)
      ) {
        productOpenSet(false);
        compagnyOpenSet(false);
        setProductHover(false);
        setTreatmentHover(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);
  const { t, i18n } = useTranslation();

  const changeSiteLang = (lang) => {
    setSiteLang(lang);
    i18n.changeLanguage(lang);
  };

  return (
      <nav className="bg-black z-50  fixed opacity 50%  flex w-full flex-nowrap items-center justify-between lg:flex-wrap lg:justify-start lg:py-4 py-2 lg:px-10 ">
        <div class="flex w-full flex-wrap items-center justify-between px-3">
          <div>
            <Link to='/'><img
              src={loadImage("logo.png")}
              alt=""
              className="w-[50%] cursor-pointer 
            "
            /></Link>
            
          </div>
          <div
            className={`transition-all duration-500 ease-in absolute w-full z-50  md:min-h-fit min-h-[50vh] bg-black flex items-center left-0 px-5 md:static md:w-auto ${
              toggle ? "top-[-900%]" : "top-[100%]"
            }`}
          >
            <ul className="flex md:flex-row flex-col items-center justify-items-center md:gap-[7vw] gap-9 text-xl text-white">
              <Link to="/">
                {" "}
                <li className={`cursor-pointer hover:text-CostumPurple active:text-CostumPurple ${location.pathname === "/" ? 'active' : ''}`}       onClick={() => toggleSet(true)} // Close navbar on click
                >
                {t("Home")}
                </li>
              </Link>
              <li  

                onClick={() => productOpenSet(!productOpen)}
                onMouseEnter={() =>{ 
                  productOpenSet(true)
                  setProductHover(false); 
                  setTreatmentHover(false); 
                  compagnyOpenSet(false);

                }}
                onMouseLeave={() => productOpenSet(false)}

                ref={productRef}
                className=" cursor-pointer hover:text-costumPurple active:text-CostumPurple hover:text-CostumPurple mt-2  "
              >
                <span> {t("product_title")} {productOpen ? (
                  <ArrowDropUpIcon style={{ fontSize: "45px" }} />
                ) : (
                  <ArrowDropDownIcon style={{ fontSize: "45px" }} />
                )}</span>{" "}
                 
                {productOpen && (
                  <ul className="md:absolute md:top-[65px] md:left-[44%] bg-white text-base text-black shadow-lg  w-44">
                    <li
                      className="py-2 px-4 hover:bg-CostumPurple hover:text-white "
                      onClick={(e) => {
                        handleDropdownClick(e); // Prevent closing on click
                        setProductHover(!productHover);
                        setTreatmentHover(false);
                        // productOpenSet(false);
                      }}
                      onMouseEnter={() => {setProductHover(true);
                        setTreatmentHover(false);
                      }}
                      // onMouseLeave={() => productOpenSet(false)}
                      >
                      <a href="#">{t("product_name_subtitle")}</a>
                      {productHover && (
                        <ul className="md:absolute  md:right-[100%] bg-white md:bottom-[-200px] text-black shadow-lg  w-48"  onClick={(e) => handleDropdownClick(e)} >
                          {productName.map((name) => (
                              <Link to={`/ProductInfo/${name}`}><li
                              key={name}
                              className="py-2 px-4 hover:bg-CostumPurple hover:text-white"
                              onClick={() => {setProductHover(false);
                                toggleSet(true);
                              }}

                            >
                            {name}
                              
                            </li>
                            </Link>
                          ))}
                        </ul>
                      )}
                    </li>
                    <li
                      className="py-2 px-4  hover:bg-CostumPurple hover:text-white"
                      onClick={(e) => {
                        e.stopPropagation();
                        setTreatmentHover(!treatmentHover);
                        setProductHover(false); 
                        // productOpenSet(false);
                        handleDropdownClick(e); // Prevent closing on click


                      }}
                      onMouseEnter={() => {setTreatmentHover(true);
                        setProductHover(false); 
                      }}


                    >
                      <a href="#">{t("treatment_type_subtitle")}</a>
                      {treatmentHover && (
                        <ul className="md:absolute  md:right-[100%] md:bottom-[-130px] bg-white text-black shadow-lg  w-48"  onClick={(e) => handleDropdownClick(e)} >
                          {treatmentType.map((productType) => (
                            <Link to={`/products/${productType}`}>
                            <li
                              key={productType}
                              className="py-2 px-4 hover:bg-CostumPurple hover:text-white"
                              onClick={() => {setTreatmentHover(false);
                                toggleSet(true);
                              }}
                            >
 {/* Translate each productType */}
 {t(treatmentTypeTranslationKeys[productType.toLowerCase()])}                            
                            </li>
                            </Link>
                          ))}
                        </ul>
                      )}
                    </li>
                  </ul>
                )}
              </li>

              <li
                onClick={() => {compagnyOpenSet(!compagnyOpen);
                  productOpenSet(false);
                }}
                onMouseEnter={() => {compagnyOpenSet(true);
                  productOpenSet(false);
                }}
                onMouseLeave={() => compagnyOpenSet(false)}


                ref={companyRef}
                className=" cursor-pointer md:flex items-center hover:text-CostumPurple active:text-CostumPurple"
              >
                <span>{t("company_title")}</span>{" "}
                <span
                  className="ml-1 text-xl "
                  onClick={() => {compagnyOpenSet(!compagnyOpen);
                    productOpenSet(false);
                  }}
                >
                  {compagnyOpen ? (
                    <ArrowDropUpIcon
                      className="text-4xl"
                      style={{ fontSize: "45px" }}
                    />
                  ) : (
                    <ArrowDropDownIcon
                      className="text-4xl"
                      style={{ fontSize: "45px" }}
                    />
                  )}
                </span>
                {compagnyOpen && (
                  <ul className="md:absolute md:top-[65px] md:left-[60%] text-base bg-white text-black shadow-lg  w-44">
                    <Link to='/About'><li className="py-2 px-4  hover:bg-CostumPurple hover:text-white"             onClick={() => toggleSet(true)} // Close navbar
                    >
                      <span>{t("about_subtitle")}</span>
                    </li></Link>
                    <Link to='/BlogPage'><li className="py-2 px-4 hover:bg-CostumPurple hover:text-white"            onClick={() => toggleSet(true)} // Close navbar
                    >
                      <span>{t("blog_subtitle")}</span>
                    </li></Link>
                    <Link to='/PaymentOpt'><li className="py-2 px-4 hover:bg-CostumPurple hover:text-white"             onClick={() => toggleSet(true)} // Close navbar
                    >
                      <span>{t("payment_option_subtitle")}</span>
                    </li></Link>
                    
                  </ul>
                )}
              </li>
              <li className="block md:hidden"               onClick={() => toggleSet(true)} // Close navbar
>
                <Link to="/Contact"><Button title="Contact Us" /></Link>
                
              </li>
            </ul>
          </div>
          <div className="flex justify-center lg:gap-9  gap-2 md:flex md:items-center">
            <p
              className="cursor-pointer  text-CostumPurple text-xl font-bold"
              onClick={() => {
                changeSiteLang(siteLang === "en" ? "fr" : "en");
              }}
            >
              {siteLang === "en" ? "Fr" : "En"}
            </p>
            <div className="hidden md:block"
            >
              <Link to="/Contact">
                <Button title= {t("contact_us_text")} />{" "}
              </Link>
            </div>
            <div
              className="text-2xl md:hidden block"
              onClick={() => toggleSet(!toggle)}

            >
              <div className="text-CostumPurple mt-1 text-[29px]">
                <ion-icon
                  className={`text-CostumPurple ${
                    toggle ? "menu-outline" : "close-outline"
                  }`}
                  name={toggle ? "menu-outline" : "close-outline"}
                ></ion-icon>
              </div>

            </div>
          </div>
        </div>
      </nav>
  );
};

export default Navbar;
