import Contact from "./pages/Contact";
import i18n from "./i18n/118n";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home/Home";
import Products from "./pages/products/Products";
import ProductInfo from "./pages/productItem/ProductInfo";
import { JsonDataProvider } from "./data/JsonDataContext";
import ScrollToTop from "./components/ScrollToTop";
import About from "./pages/About";
import BlogPage from "./pages/blog/BlogPage";
import PaymentOpt from "./pages/PaymentOpt";
import { BlogDataProvider } from "./data/blogData";
import BlogDetail from "./pages/BlogDetail";
import { BannerDataProvider } from "./data/BannerDataContext";


function App() {
  return (
    <div className="App overflow-hidden">
      <Router>
      <ScrollToTop />
      <BannerDataProvider> 
      <BlogDataProvider>
        <JsonDataProvider>
        <I18nextProvider i18n={i18n}>
          <Navbar />
          <Routes>
            <Route path="/contact" element={<Contact />} />
            <Route path="/navbar" element={<Navbar />} />
            <Route path="/" element={<Home />} />
            <Route path="/products/:treatmentType" element={<Products />} />
            <Route path="/products" element={<Products />} />
            <Route path="/BlogPage" element={<BlogPage />} />
            <Route path="/About" element={<About />} />
            <Route path="/PaymentOpt" element={<PaymentOpt />} />
            <Route path="/BlogDetail" element={<BlogDetail />} />
            <Route path="/ProductInfo/:title" element={<ProductInfo />} />
          </Routes>
          <Footer />
        </I18nextProvider>
        </JsonDataProvider>
        </BlogDataProvider>
        </BannerDataProvider>
      </Router>
    </div>
  );
}

export default App;
