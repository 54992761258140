import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BASE_URL;

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const submitContactForm = async (formData) => {
  try {
    const response = await api.post("/contactForm", formData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const subscribeNewsletter = async (email) => {
  try {
    const response = await api.post("/newsletter", {"user_email": email} );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export default api;
