import React from "react";
import Banner from "./componentProductItem/Banner";
import ProductFeature from "./componentProductItem/ProductFeature";
import ProductFAQ from "./componentProductItem/ProductFAQ";
import ProductTreatment from "./componentProductItem/ProductTreatment";
import { useParams } from "react-router-dom";

const ProductInfo = () => {

  const { title } = useParams();
  return (
    <div>
        <Banner componentTitle={title}/>
        <ProductFeature componentTitle={title}/>
        <ProductTreatment componentTitle={title}/>
        <ProductFAQ componentTitle={title}/>
    </div>
  );
};

export default ProductInfo;
