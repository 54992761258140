import React from 'react'
import { useTranslation } from 'react-i18next';

const About = () => {
    const loadImage = (img) => {
        return require(`../assets/${img}`);
      };
      const {t} = useTranslation();
  return (
    <div className=''>
      <div className="items-center justify-center w-full mt-28">
      <div className='grid md:grid-cols-2 grid-cols-1 gap-10 px-20 py-28 items-center'>
        <div>
        <h1 className='text-6xl mb-12  text-CostumPurple'>Orisha</h1>
            {t("about_us_page_msg")}
        </div>
        <div>
            <img className='h-[130%] w-[90%]' src={loadImage('about-pic.png')} alt="" />
        </div>
      </div>
      </div>

    </div>
  )
}

export default About
