import React from "react";
import Button from "../../../components/ui component/Button";
import '../../../style/product_card.css';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ProductCard = ({ product }) => {

  const { t,i18n } = useTranslation();
  return (
    <div>
      <div className="	">
        <div className="bg-white shadow-lg rounded-lg p-4 text-centershadow h-[510px]">
          <img
            src={product.images[0]}
            alt={product.title}
            className="mx-auto mb-4 h-72 w-64 object-contain"
          />
          <h3 className="font-semibold text-lg mb-2">{product.title}</h3>
          <p className="text-gray-500 mb-4 truncated-text">{product[i18n.language].description[0]}</p>
          <Link to={`/ProductInfo/${product.title}`}>
            <Button title={t("learn_more_button_text")} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
