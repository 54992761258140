import React, { useState, useEffect} from "react";
import ProductCard from "../../products/components/ProductCard";
import '../../../style/component.css';
import { useJsonData } from "../../../data/JsonDataContext";
import { useTranslation } from "react-i18next";

const TheRightSolution = () => {
  const { t, i18n } = useTranslation();
  // Original category keys
  const categoryMapping = {
    skin: "treatmentType_Opt1",
    hair: "treatmentType_Opt2",
    body: "treatmentType_Opt3",
    "pelvic floor": "treatmentType_Opt4",
    others: "treatmentType_Opt5",
  };

  const [selectedCategoryKey, setSelectedCategoryKey] = useState("skin");

  const products = useJsonData();
 
// Filter products
const productsDisplayed = products.filter((product) => {
  const applicationAreas = product.application_area; // Array of strings
  const mappedCategory = selectedCategoryKey; // Use key for filtering
  return (
    Array.isArray(applicationAreas) && // Ensure it's an array
    mappedCategory && // Ensure a valid mapping exists
    applicationAreas.some((area) =>
      area.toLowerCase().includes(mappedCategory.toLowerCase())
    )
  );
});

   
  // Generate translated categories
  const translatedCategories = Object.fromEntries(
    Object.entries(categoryMapping).map(([key, value]) => [key, t(value)])
  );

  // Update selected category when language changes
  useEffect(() => {
    setSelectedCategoryKey((prevKey) => prevKey); // Retain selected key
  }, [i18n.language]);
  return (
    <div className="py-12">
      <section className="container mx-auto py-20 px-10">
        <h2 className="text-3xl font-bold text-center mb-10 text-CostumPurple">
          {t("main_page_product_filter_msg")}
        </h2>
        <div className="flex justify-center mb-6 space-x-4 text-gray-700 md:gap-9 md:text-xl py-9">
        {Object.keys(categoryMapping).map((key) => (
            <button
              key={key}
              className={`pb-2 md:w-32 ${
                selectedCategoryKey === key
                  ? "border-b-4 border-CostumPurple active_title"
                  : ""
              }`}
              onClick={() => setSelectedCategoryKey(key)}
            >
              {translatedCategories[key]}
            </button>
          ))}

        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {productsDisplayed.map((product, index) => (
            <ProductCard key={index} product={product} />
          ))}
        </div>
      </section>
    </div>
  );
};

export default TheRightSolution;
