import React from "react";
import { useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useJsonData } from "../../../data/JsonDataContext";
import { useTranslation } from "react-i18next";

const ProductFAQ = ({componentTitle}) => {
  const [openIndex, setOpenIndex] = useState(null);
  const {i18n} = useTranslation();

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  const data = useJsonData();
  const productToDisplay = data.find((product) => product.title === componentTitle);
  return (
    <section className="py-12 bg-gray-50 text-center">
      <h1 className="text-3xl font-bold mt-20 text-CostumPurple">FAQ</h1>
      <div className="max-w mx-auto text-left md:p-32 p-7">
        {Object.keys(productToDisplay[i18n.language].FAQ).map((question, index) => (
          <div key={index} className="border-b border-b-gray-300 py-7">
            <button
              className="w-full text-left font-medium text-xl"
              onClick={() => toggleFAQ(index)}
            >
              {question}
              {openIndex === index ? (
                <RemoveCircleOutlineIcon className="text-CostumPurple float-right" />
              ) : (
                <AddCircleOutlineIcon className="text-CostumPurple float-right" />
              )}
            </button>
            {openIndex === index && (
              <p className="text-gray-600 text-base mt-2">
               {productToDisplay[i18n.language].FAQ[question]}
              </p>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default ProductFAQ;
