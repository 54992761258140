import React from 'react'
import BlogCard from './component/BlogCard'
import { useBlogData } from '../../data/blogData';
import { useTranslation } from "react-i18next";

const BlogPage = () => {
  const { t, i18n } = useTranslation();
  const blogData = useBlogData();

  return (
    <div className="container mx-auto p-6 grid grid-cols-1 md:grid-cols-3 gap-6 mt-28">
      {blogData.map((blog) => (
        <BlogCard
          key={blog[i18n.language].main_title}
          blog={blog} // Pass the entire blog object
          title={blog[i18n.language].main_title}
          date={blog[i18n.language].published}
          description={blog[i18n.language].paragraph}
        />
      ))}
    </div>
  );
};

export default BlogPage;
