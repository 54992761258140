import React from "react";

const Button = ({ title }) => {
  return (
    <div>
<button className="bg-CostumPurple border-2 border-CostumPurple text-white py-2 px-5 rounded-full hover:bg-white hover:border-CostumPurple text-base hover:text-CostumPurple">
{title}
      </button>
    </div>
  );
};

export default Button;
