import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const BlogDetail = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { blog } = location.state || {}; // Extract the full blog data

  if (!blog) {
    return <div className="mt-32 text-center">No blog data available.</div>;
  }

  // Select the content for the current language
  const localizedBlog = blog[i18n.language];

  if (!localizedBlog) {
    return <div className="mt-32  text-center">No localized blog data available.</div>;
  }

  return (
    <div className="mt-32 p-6 max-w-3xl mx-auto">
      <h1 className="text-2xl font-bold mb-4">{localizedBlog.main_title}</h1>
      <img
        src="https://s3.us-east-1.amazonaws.com/o-risha.com/Blog+Data/orisha_revolution.jpg"
        alt={localizedBlog.main_title}
        className="w-full  mb-6 mt-12 h-72 object-cover"
      />
      {localizedBlog.paragraph.map((para, index) => (
        <p key={index} className="text-gray-700 mb-4">
          {para}
        </p>
      ))}
    </div>
  );
};

export default BlogDetail;
