import React, { useState } from "react";
import { useJsonData } from "../data/JsonDataContext";
import { Link } from "react-router-dom";
import "../style/component.css";
import { subscribeNewsletter } from "../services/apiService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";



const Footer = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const products = useJsonData();
  const { t } = useTranslation();

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubscribe = async () => {
    if (!email) {
      setEmailError("Email is required");
    } else if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
  
      try {
        const response = await subscribeNewsletter(email);
  
        toast.success(response.message || "Subscribed successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
  
        setEmail(""); // Clear the email field
      } catch (error) {
  
        toast.error(
          error.response?.data?.message || "Failed to subscribe. Please try again.",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    }
  };
  return (
    <div>
      <footer className="text-center text-surface/75 bg-black text-white lg:text-left">
      <ToastContainer />
        <div className="flex items-center justify-center border-b-2 border-neutral-200 p-6 dark:border-white/10 lg:justify-between">
          <div className="me-12 hidden lg:block">
            <span>{t("social_medias_msg")}</span>
          </div>
          <div className="flex justify-center">
            <a href="#!" className="me-6 [&>svg]:h-4 [&>svg]:w-4">
              {/* Social icons */}
            </a>
          </div>
        </div>

        <div className="mx-6 py-10 text-center md:text-left">
          <div className="grid-1 grid gap-8 md:grid-cols-2 lg:grid-cols-4 justify-center">
            <div>
              <h6 className="mb-4 flex justify-center font-semibold uppercase md:justify-start">
                {t("footer_links_title")}
              </h6>
              <Link to='/'>
              <p className="mb-4">
                <a href="#!">{t("Home")}</a>
              </p>
              </Link>
              <Link to='/About'>
              <p className="mb-4">
                <a href="#!">{t("about_subtitle")}</a>
              </p>
              </Link>
              <Link to='/BlogPage'>
              <p className="mb-4">
                <a href="#!">{t("blog_subtitle")}</a>
              </p>
              </Link>
              <Link to='/PaymentOpt'>
              <p className="mb-4">
                <a href="#!">{t("payment_option_subtitle")}</a>
              </p>
              </Link>
              <Link to='/contact'>
              <p className="mb-4">
                <a href="#!">{t("contact_us_text")}</a>
              </p>
              </Link>
            </div>
            <div>
              <h6 className="mb-4 flex justify-center font-semibold uppercase md:justify-start">
              {t("footer_products_title")}
              </h6>
              <ul>
                {products.map((product, index) => (
                  <li className="mb-4" key={index}>
                    <Link to={`/ProductInfo/${product.title}`}>
                      {product.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h6 className="mb-4 flex justify-center font-semibold uppercase md:justify-start">
              {t("footer_contact")}
              </h6>
              <p className="mb-4 flex items-center justify-center md:justify-start">
                Montreal, QC, Canada
              </p>
              <p className="mb-4 flex items-center justify-center md:justify-start">
                <a href="mailto:contact@o-risha.com" className="text-white">
                  contact@o-risha.com
                </a>
              </p>
              <p className="mb-4 flex items-center justify-center md:justify-start">
                <a href="tel:+14383679453" className="text-white">
                  (438) 367-9453
                </a>
              </p>
            </div>
            <div>
              <h6 className="mb-4 flex justify-center font-semibold uppercase md:justify-start">
              {t("newsletter_msg")}
              </h6>
              <div className="flex flex-col space-y-4">
                <input
                  type="email"
                  placeholder={t("newsletter_email_msg")}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="p-2 focus:outline-none rounded bg-gray-200 text-black"
                />
                {emailError && (
                  <p className="text-red-500 text-sm">{emailError}</p>
                )}
                <button
                  className="bg-CostumPurple hover:bg-purple-600 text-white py-2 rounded"
                  onClick={handleSubscribe}
                >
                  {t("subscribe_button")}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-black/5 p-6 text-center">
          <span>© 2024 Copyright</span>
          <a className="font-semibold px-1" href="#">
            Orisha
          </a>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
