import React from "react";
import Button from "../../components/ui component/Button";
import { Link } from "react-router-dom";
import TheRightSolution from "./componentHome/TheRightSolution";
import SetApart from "./componentHome/SetApart";
import { useBannerData } from "../../data/BannerDataContext";
import { useTranslation } from "react-i18next";

const Home = () => {

  const Banners=useBannerData();
  const homeBanner= Banners[7];
  const { t } = useTranslation();
  return (
    <div>
      <section
        className="relative bg-cover bg-center h-[80vh]  top-12 "
        style={{ backgroundImage: `url(${homeBanner})` }}
      >
          <div className="absolute inset-0 bg-CostumPurple bg-opacity-55"></div>
        <div className="absolute inset-0 flex flex-col items-center justify-center text-center text-white md:px-10 px-3">
          <h1 className="md:text-4xl text-2xl  font-bold uppercase mb-4 text-white ">
            {t("main_page_banner_msg")}
          </h1>
          <div className="mt-7">
            <Link to="/products">
              <Button title={t("main_page_banner_button_text")} />
            </Link>
          </div>
        </div>
      </section>
      <TheRightSolution />
      <SetApart/>
    </div>
  );
};

export default Home;
