import React from 'react'
import { useTranslation } from 'react-i18next';

const SetApart = () => {
  const loadImage = (img) => {
    return require(`../../../assets/${img}`);
  };
  const { t } = useTranslation();

    const features = [
        {
          title: `${t("strong_brand_title")}`,
          description: `${t("strong_brand_text")}`,
          icon:loadImage('icon 2.png') ,
        },
        {
          title: `${t("innovative_title")}`,
          description: `${t("innovative_text")}`,
          icon:loadImage('icon 3.png') ,
        },
        {
          title: `${t("global_reach_title")}`,
          description: `${t("global_reach_text")}`,
          icon:loadImage('icon 1.png') ,
        },
      ];
      
    return (
        <section className="py-20 bg-white text-center">
          <h2 className="text-4xl font-bold mb-8 ">{t("what_set_osiha_apart_qst")}</h2>
          <div className="flex flex-col md:flex-row justify-center gap-10 mt-36" >
            {features.map((feature, index) => (
              <div key={index} className="flex flex-col items-center w-full md:w-1/4 p-4 bg-white rounded-md shadow-lg ">
                <div className="text-5xl text-CostumPurple mb-4">
                <div className="mb-4">
              <img
                src={feature.icon}
                alt={feature.title}
                className="w-16 h-16 object-contain" // Tailwind classes for image sizing
              />
            </div>
                </div>
                <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                <p className="text-gray-600">{feature.description}</p>
              </div>
            ))}
          </div>
        </section>
      );
}

export default SetApart
