import React, { useState } from "react";
import { useEffect } from "react";
// import ProductFilter from "./components/ProductFilter";
import ProductFilter from "./components/ProductFilter";
import ProductCard from "./components/ProductCard";
import { useJsonData } from "../../data/JsonDataContext";
import { useParams } from "react-router-dom"; 
import { useBannerData } from "../../data/BannerDataContext";
import { useTranslation } from "react-i18next";




const Products = () => {
  const { t } = useTranslation();

  const Banners=useBannerData();
  const homeBanner= Banners[7];
  const { treatmentType } = useParams();
  const data = useJsonData();
  // Get unique titles and application areas
  const titles = [...new Set(data.map((item) => item.title))];
  const areas = [...new Set(data.flatMap((item) => item.application_area))];
  useEffect(() => {
    if (treatmentType) {
      setSelectedArea(treatmentType);
    }
  }, [treatmentType]);

  // Filter states
  const [selectedTitle, setSelectedTitle] = useState("");
  const [selectedArea, setSelectedArea] = useState("");

  // Separate filters for product name and treatment type
  const filteredByTitle = data.filter((product) => {
    return selectedTitle ? product.title === selectedTitle : true;
  });

  const filteredByArea = data.filter((product) => {
    return selectedArea ? product.application_area.includes(selectedArea) : true;
  });


  return (
    <div>
      <section
        className="relative bg-cover bg-center h-96 top-12"
        style={{ backgroundImage: `url(${homeBanner})` }}
      >
                  <div className="absolute inset-0 bg-CostumPurple bg-opacity-55"></div>

        <div className=" inset-0 flex flex-col items-center justify-center text-center text-white">
         
        </div>
      </section>
      <div className="pb-28 md:px-24 px-8">
        <ProductFilter  
        titles={titles}
        areas={areas}
        selectedTitle={selectedTitle}
        setSelectedTitle={setSelectedTitle}
        selectedArea={selectedArea}
        setSelectedArea={setSelectedArea}
        />
        {/* Display Products by Selected Title */}
        {selectedTitle && (
          <div className="mt-12">
            <h2 className="text-2xl font-semibold mb-6">{t("title_selectedOption1_products")} <span className="text-CostumPurple">{selectedTitle}</span> </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
              {filteredByTitle.map((product) => (
                <ProductCard key={product.id} product={product} />
              ))}
            </div>
          </div>
        )}

       
        {selectedArea && (
          <div className="mt-12">
            <h2 className="text-2xl font-semibold mb-6">{t("title_selectedOption2_products" )}<span className="text-CostumPurple">{selectedArea}</span> </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
              {filteredByArea.map((product) => (
                <ProductCard key={product.id} product={product} />
              ))}
            </div>
          </div>
        )}

        {/* Display All Products if No Filters Selected */}
        {!selectedTitle && !selectedArea && (
          <div className="mt-12">
            <h2 className="text-2xl font-semibold mb-6">{t("title_selectedOption3_products")}</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
              {data.map((product) => (
                <ProductCard key={product.id} product={product} />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Products;
